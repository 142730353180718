'use client';

import { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';

import Button from '@/components/Button';
import Logo from '@/components/Logo';
import LottieLazyWrapper from '@/components/LottieLazyWrapper';
import { FullscreenContextActions } from '@/context/FullScreenContext';
import animationData from '@/public/lottiefiles/done.json';
import { sendLog } from '@/services/logs';
import getBrandCookie from '@/utils/brandCookie/client';

import styles from './styles.module.scss';

interface Props {
  nbFilesToUpload: number;
}

function DoneView({ nbFilesToUpload }: Props) {
  const { setPopupChildren } = useContext(FullscreenContextActions);
  const pathname = usePathname();
  const isHomePage = pathname === '/';

  useEffect(() => {
    sendLog('ui_generic_screenEnter', {
      screen: 'photosUploaded',
    });
  }, []);

  return (
    <div className={styles.container} data-testid="done-view-container">
      <Logo brandCookie={getBrandCookie()} />
      <div>
        <p
          className={styles.title}
          data-testid="photo-uploaded-message"
        >{`Your photo${
          nbFilesToUpload > 1 ? 's have' : ' has'
        } been added to cart`}</p>

        <LottieLazyWrapper
          width={130}
          height={130}
          options={{
            loop: false,
            animationData,
          }}
        />
      </div>
      <div
        className={clsx(styles.buttonsContainer, styles.flexDirectionColumn)}
      >
        <Button
          href={!isHomePage ? '/' : undefined}
          type={!isHomePage ? 'link' : undefined}
          theme="secondary"
          callback={() => {
            setPopupChildren();
            sendLog('ui_homepage_uploader_addMoreTapped');
          }}
          width="full"
          dataTestid="button-continue-shopping"
        >
          <div className={styles.buttonContent}>
            <span>Continue shopping</span>
          </div>
        </Button>

        <Button
          callback={() => {
            sendLog('ui_homepage_uploader_goToCartTapped');
            setTimeout(() => {
              setPopupChildren();
            }, 500);
          }}
          href="/cart"
          type="link"
          width="full"
          dataTestid="button-go-to-cart"
        >
          <div className={styles.buttonContent}>
            <span>Go to Cart</span>
          </div>
        </Button>
      </div>
      {/* This is to fix a scroll bug on mobile on safari */}
      <div className={styles.scrollWhiteDiv} />
    </div>
  );
}

export default DoneView;
